import React from 'react'

const MochaSVG = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 112 126.38'
  >
    <title>mocha</title>
    <g id='plain'>
      <polygon
        points='91.78 58.77 89.48 64.19 94.09 64.19 91.78 58.77'
      />
      <path
        d='M60.91,52h4a1,1,0,0,0,.54-.24C67.77,49,68.1,43.6,68.1,41.7a.7.7,0,0,0-.68-.7h-9a.68.68,0,0,0-.68.68c.07,1.9.34,7.36,2.65,10.08A.65.65,0,0,0,60.91,52Zm4.41-5.16C67,46.23,67,45.62,67,45.62a10.69,10.69,0,0,1-2,5.24.51.51,0,0,1-.41.15H61.25c-.14,0-.34,0-.41-.15a9.6,9.6,0,0,1-1.7-3.77S63.08,47.65,65.32,46.84Z'
        transform='translate(-8 -0.81)'
      />
      <path
        d='M116.42,29.94,67.48,1.72a6.83,6.83,0,0,0-6.83,0L11.6,29.94A6.9,6.9,0,0,0,8,35.78V92.22a7,7,0,0,0,3.58,5.83l48.94,28.22a6.83,6.83,0,0,0,6.83,0L116.4,98.06a7.14,7.14,0,0,0,3.6-5.83V35.78A7,7,0,0,0,116.42,29.94ZM112,88.29a5.77,5.77,0,0,1-2.94,5L66.86,117.66a5.65,5.65,0,0,1-5.84,0L18.87,93.31a5.63,5.63,0,0,1-2.87-5V39.58a5.71,5.71,0,0,1,2.87-5L61,10.2a5.6,5.6,0,0,1,5.82,0l42.21,24.35a5.69,5.69,0,0,1,2.93,5Z'
        transform='translate(-8 -0.81)'
      />
      <circle cx='40' cy='62.53' r='4.84' />
      <path
        d='M108.06,36.32,65.85,12a3.87,3.87,0,0,0-1.91-.54A4.06,4.06,0,0,0,62,12L19.87,36.32A3.88,3.88,0,0,0,18,39.65V88.35a3.87,3.87,0,0,0,1.87,3.32L62,116a3.62,3.62,0,0,0,1.89.54,3.82,3.82,0,0,0,1.9-.54l42.23-24.35A3.94,3.94,0,0,0,110,88.35V39.65A3.94,3.94,0,0,0,108.06,36.32ZM67,68.19a4.8,4.8,0,0,0,3.65-1.69L72.17,68a7.7,7.7,0,0,1-1.08,1,5.9,5.9,0,0,1-1.22.75,5,5,0,0,1-1.42.47,8.33,8.33,0,0,1-1.83.14,6.72,6.72,0,0,1-4.88-2,6.1,6.1,0,0,1-1.3-2.24A7.34,7.34,0,0,1,60,63.39v-.07a7.12,7.12,0,0,1,.42-2.71,7.63,7.63,0,0,1,1.36-2.24A6.35,6.35,0,0,1,64,56.88a7.53,7.53,0,0,1,2.83-.54,13.09,13.09,0,0,1,1.69.14c.54.14.95.27,1.42.41a6.43,6.43,0,0,1,1.15.68,11.79,11.79,0,0,1,1,.88l-1.56,1.63A4.83,4.83,0,1,0,67,68.19ZM64.17,30.56c-.34.47.07,1.63.95,3a2.83,2.83,0,0,1-.95,4,3.83,3.83,0,0,0-.75-2.31C61.8,32.79,63.29,31.64,64.17,30.56Zm-2.71,2.51c-.27.27,0,1.09.61,2a1.91,1.91,0,0,1-.61,2.71A2.74,2.74,0,0,0,61,36.25C59.9,34.56,60.85,33.81,61.46,33.07ZM56.5,39H69.26a.64.64,0,0,1,.68.63C69.94,42,69.6,50,66.2,53.77a.64.64,0,0,1-.47.23H60a.67.67,0,0,1-.47-.26C56.16,49.88,55.83,42,55.83,39.66A.66.66,0,0,1,56.5,39ZM21,49H55.35s.07,0,.07,1H21ZM55,63.32v.07a7.37,7.37,0,0,1-.47,2.71,6.7,6.7,0,0,1-1.46,2.24,6.4,6.4,0,0,1-2.22,1.49,6.63,6.63,0,0,1-2.84.54,7.86,7.86,0,0,1-2.84-.54,6.47,6.47,0,0,1-2.24-1.49,6.3,6.3,0,0,1-1.41-2.24A7.16,7.16,0,0,1,41,63.39v-.07a7.43,7.43,0,0,1,.53-2.71A6.79,6.79,0,0,1,43,58.37a6.46,6.46,0,0,1,2.23-1.49,6.66,6.66,0,0,1,2.85-.54,7.88,7.88,0,0,1,2.85.54,6.48,6.48,0,0,1,2.24,1.49,6.18,6.18,0,0,1,1.35,2.24A7.14,7.14,0,0,1,55,63.32ZM29.1,67H29l-4-6.46V70H22V57h3l4.14,6.38L33.23,57H36V70H33V60.47ZM107,75H21V74h86ZM86,62V57h2V70H86V64H79v6H77V57h2v5Zm6.73,8,6-13h2.17l6,13h-2.51L103,67H96.6l-1.42,3ZM107,50H70.41c0-1,.07-1,.07-1H107Z'
        transform='translate(-8 -0.81)'
      />
    </g>
  </svg>
)

export default MochaSVG
