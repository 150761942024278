import React from 'react'

const WebpackSVG = () => (
  <svg id='angularjs' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'>
    <title>Artboard 13</title>
    <g id='plain'>
      <path
        d='M117.29,98.1,66.24,127V104.49L98,87,117.29,98.1Zm3.5-3.16V34.55L102.11,45.35V84.16l18.67,10.77ZM10.71,98.1l51,28.88V104.49L29.94,87Zm-3.5-3.16V34.55L25.89,45.35V84.16Zm2.19-64.3L61.76,1V22.76L28.21,41.21l-.27.15Zm109.18,0L66.24,1V22.76L99.79,41.2l.27.15,18.54-10.71Z'
      />
      <path
        d='M61.76,99.37,30.37,82.1V47.92L61.76,66Zm4.48,0L97.63,82.12V47.92L66.24,66ZM32.5,44,64,26.66,95.5,44,64,62.16,32.5,44Z'
      />
    </g>
  </svg>
)

export default WebpackSVG
